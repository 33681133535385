/* Modules */
import React, { useState, useEffect } from "react"
import { Link } from 'react-router-dom'

/* Composant */
import BarreDeRecherche from "./BarreDeRecherche"

/* Média */
import LogoBlanc from "../assets_opti/logo/V2RB_opti.webp"
import LogoNoir from "../assets_opti/logo/V2RN_opti.webp"
import VideoMP4 from "../assets_opti/home/videoMain.mov"
import poster from "../assets_opti/home/pageAccueil.webp"
import LogoPetit from '../assets_opti/logo/V2CN_opti.webp'
import { productsService } from "../_services/products.service"

/* Code */
export default function Header(props) {
    /* Affichage la vidéo que sur la page Home */
    const page = props.page;

    /* Réduction du header lors d'un scroll */
    const [scroll, setScroll] = useState(false);
    
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 20);
        });
    }, []);

    /* Menu Burger */
    let [showLinks, setShowLinks] = useState(false);

    const handleShowLinks = () => {
        setShowLinks(!showLinks);
    };

    document.addEventListener("click", function handleClickOutsideMenuBurger(event) {
        const menu = document.getElementById('showBurger');

        if(menu && !menu.contains(event.target)) {
            setShowLinks(!showLinks);
        }
    });

    /* Gestion de la barre de recherche */
    document.addEventListener('click', function handleClickOutsideBar(event) {
        const box = document.getElementById('box');
        const box_second = document.getElementById('box_second');
        const glass = document.getElementById('glass');

        if (box && !box_second.contains(event.target) && !glass.contains(event.target)) {
            if (!box.classList.contains("hideSearchingBar")) {
                box.classList.add("hideSearchingBar");
            }
        }
    });

    function handleClickGlass() {
        const box = document.getElementById('box');
        const input = document.getElementById('barreDeRecherche_input');
        
        if (box.classList.contains("hideSearchingBar")) {
            box.classList.remove("hideSearchingBar");
            input.focus();
        } else {
            box.classList.add("hideSearchingBar");
        }
    }

    // <Link to="/mon-panier" aria-label="Onglet Mon Panier" title="Accéder à votre panier !"><i className="fa-solid fa-cart-shopping"></i></Link>

    return (
        <>
            { page === "Home" ?
                <>
                    <video loop autoPlay muted playsInline poster={poster} preload="metadata">
                        <source src={VideoMP4} type="video/mp4"></source>
                    </video>
                    <div className="header_infos">
                        <p className="header_infos_titre">Découvrez notre sélection du<br />moment à prix cassé !</p>
                        <Link to="/promo/carrelage-et-parquet" className="header_infos_bouton" aria-label="Page promo du moment" title="Accéder aux promos du moment !"><span>EN PROFITER</span></Link>
                    </div>
                </>
            : 
                null
            }
            <nav className={`header_nav${scroll ? " scroll" : ""}${showLinks ? " showBurger" : ""}`} id={`${showLinks ? "showBurger" : ""}`}>
                <button aria-label="Menu Burger" className="burger_button" onClick={handleShowLinks}>
                    <span className="burger_bar"></span>
                </button>
                <button aria-label="Loupe pour recherche" className="loupe_button">
                    <Link to="/recherche/valeur=" aria-label="Page Recherche" title="Consulter uniquement les produits correspondants à votre recherche !">
                        <i className="fa-solid fa-magnifying-glass magnifying-glass"></i>
                    </Link>
                </button>
                <div className="header_gauche">
                    <div className="header_gauche_megamenu">
                        <div className="header_gauche_megamenu_carrelage">
                            <Link to="/carrelage" className="bouton_carrelage" aria-label="Nos Carrelages">
                                <h2>Carrelage</h2>
                            </Link>
                            <div className="categories_carrelage">
                                <div className="categories">
                                    <div className="categories_sol">
                                        <Link to={"/carrelage/sols-&-murs/pas-de-sous-categorie/stock-et-commande"} className="categories_sol_titre">
                                            <span className="categories_sol_titre_logo"><img alt="Catégorie sélectionnée" src={LogoPetit}></img></span>
                                            <h3>Sols & Murs</h3>
                                        </Link>
                                        <ul className="categories_sol_infos">
                                            <li><Link to={"/carrelage/sols-&-murs/imitation-marbre/stock-et-commande"}>
                                                Imitation Marbre</Link></li>
                                            <li><Link to={"/carrelage/sols-&-murs/imitation-pierre/stock-et-commande"}>
                                                Imitation Pierre</Link></li>
                                            <li><Link to={"/carrelage/sols-&-murs/imitation-travertin/stock-et-commande"}>
                                                Imitation Travertin</Link></li>
                                            <li><Link to={"/carrelage/sols-&-murs/les-bétons-cirés/stock-et-commande"}>
                                                Les Bétons Cirés</Link></li>
                                            <li><Link to={"/carrelage/sols-&-murs/imitation-terrazzo/stock-et-commande"}>
                                                Imitation Terrazzo</Link></li>
                                            <li><Link to={"/carrelage/sols-&-murs/imitation-bois/stock-et-commande"}>
                                                Imitation Bois</Link></li>
                                            <li><Link to={"/carrelage/sols-&-murs/imitation-carreaux-de-ciment/stock-et-commande"}>
                                                Imitation Carreaux de Ciment</Link></li>
                                            <li><Link to={"/carrelage/sols-&-murs/les-formats-xxl/stock-et-commande"}>
                                                Les Formats XXL</Link></li>
                                            <li><Link to={"/carrelage/sols-&-murs/les-hexagonaux/stock-et-commande"}>
                                                Les Hexagonaux</Link></li>
                                        </ul>
                                    </div>
                                    <div className="categories_fai">
                                        <Link to={"/carrelage/les-faïences-murales/pas-de-sous-categorie/stock-et-commande"} className="categories_fai_titre">
                                            <span className="categories_fai_titre_logo"><img alt="Catégorie sélectionnée" src={LogoPetit}></img></span>
                                            <h3>Les Faïences Murales</h3>
                                        </Link>
                                        <ul className="categories_fai_infos">
                                            <li><Link to={"/carrelage/les-faïences-murales/pour-les-archis/stock-et-commande"}>
                                                Pour les Archis</Link></li>
                                            <li><Link to={"/carrelage/les-faïences-murales/3d---les-reliefs/stock-et-commande"}>
                                                3D / Les Reliefs</Link></li>
                                            <li><Link to={"/carrelage/les-faïences-murales/imitation-zellige/stock-et-commande"}>
                                                Imitation Zellige</Link></li>
                                            <li><Link to={"/carrelage/les-faïences-murales/les-coordonnées-sols-&-murs/stock-et-commande"}>
                                                Les Coordonnées Sol et Murs</Link></li>
                                            <li><Link to={"/carrelage/les-faïences-murales/les-motifs/stock-et-commande"}>
                                                Les Motifs</Link></li>
                                            <li><Link to={"/carrelage/les-faïences-murales/les-unis/stock-et-commande"}>
                                                Les Unis</Link></li>
                                        </ul>
                                    </div>
                                    <div className="categories_ext">
                                        <Link to={"/carrelage/les-extérieurs/pas-de-sous-categorie/stock-et-commande"} className="categories_ext_titre">
                                            <span className="categories_ext_titre_logo"><img alt="Catégorie sélectionnée" src={LogoPetit}></img></span>
                                            <h3>Les Extérieurs</h3>
                                        </Link>
                                        <ul className="categories_ext_infos">
                                            <li><Link to={"/carrelage/les-extérieurs/les-fortes-épaisseurs/stock-et-commande"}>
                                                Les Fortes Épaisseurs</Link></li>
                                            <li><Link to={"/carrelage/les-extérieurs/antidérapants/stock-et-commande"}>
                                                Antidérapants</Link></li>
                                            <li><Link to={"/carrelage/les-extérieurs/piscines/stock-et-commande"}>
                                                Piscines</Link></li>
                                            <li><Link to={"/carrelage/les-extérieurs/les-pavés/stock-et-commande"}>
                                                Les Pavés</Link></li>
                                        </ul>
                                    </div>
                                    <div className="categories_pie">
                                        <Link to={"/carrelage/les-terrazzos/pas-de-sous-categorie/stock-et-commande"} className="categories_pie_titre">
                                            <span className="categories_pie_titre_logo"><img alt="Catégorie sélectionnée" src={LogoPetit}></img></span>
                                            <h3>Les Terrazzos</h3>
                                        </Link>
                                        <ul className="categories_pie_infos">
                                            <li><Link to={"/carrelage/les-terrazzos/les-ciments/stock-et-commande"}>
                                                Les Ciments</Link></li>
                                            <li><Link to={"/carrelage/les-terrazzos/les-résines/stock-et-commande"}>
                                                Les Résines</Link></li>
                                        </ul>
                                    </div>
                                    <div className="categories_mos">
                                        <Link to={"/carrelage/les-mosaïques/pas-de-sous-categorie/stock-et-commande"} className="categories_mos_titre">
                                            <span className="categories_mos_titre_logo"><img alt="Catégorie sélectionnée" src={LogoPetit}></img></span>
                                            <h3>Les Mosaïques</h3>
                                        </Link>
                                        <ul className="categories_mos_infos">
                                            <li><Link to={"/carrelage/les-mosaïques/hexagonal/stock-et-commande"}>
                                                Hexagonal</Link></li>
                                            <li><Link to={"/carrelage/les-mosaïques/verre/stock-et-commande"}>
                                                Verre</Link></li>
                                            <li><Link to={"/carrelage/les-mosaïques/cérame/stock-et-commande"}>
                                                Cérame</Link></li>
                                            <li><Link to={"/carrelage/les-mosaïques/pierre-&-marbre/stock-et-commande"}>
                                                Pierre & Marbre</Link></li>
                                        </ul>
                                    </div>
                                    <div className="categories_accCar">
                                        <Link to={"/carrelage/accessoires-carrelage/pas-de-sous-categorie/stock-et-commande"} className="categories_accCar_titre">
                                            <span className="categories_accCar_titre_logo"><img alt="Catégorie sélectionnée" src={LogoPetit}></img></span>
                                            <h3>Accessoires Carrelage</h3>
                                        </Link>
                                        <ul className="categories_accCar_infos">
                                            <li><Link to={"/carrelage/accessoires-carrelage/plinthes/stock-et-commande"}>
                                                Plinthes</Link></li>
                                            <li><Link to={"/carrelage/accessoires-carrelage/colles/stock-et-commande"}>
                                                Colles</Link></li>
                                            <li><Link to={"/carrelage/accessoires-carrelage/joints/stock-et-commande"}>
                                                Joints</Link></li>
                                            <li><Link to={"/carrelage/accessoires-carrelage/croisillons/stock-et-commande"}>
                                                Croisillons</Link></li>
                                            <li><Link to={"/carrelage/accessoires-carrelage/produits-d'entretien/stock-et-commande"}>
                                                Produits d'entretien</Link></li>
                                            <li><Link to={"/carrelage/accessoires-carrelage/ragréages-et-primaires/stock-et-commande"}>
                                                Ragréages et primaires</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="header_gauche_megamenu_parquet">
                            <Link to="/parquet" className="bouton_parquet" aria-label="Nos Parquets">
                                <h2>Parquet</h2>
                            </Link>
                            <div className="categories_parquet">
                                <div className="categories">
                                    <div className="categories_mas">
                                        <Link to="/parquet/massif/pas-de-sous-categorie/stock-et-commande" className="categories_mas_titre">
                                            <span className="categories_mas_titre_logo"><img alt="Catégorie sélectionnée" src={LogoPetit}></img></span>
                                            <h3>Massif</h3>
                                        </Link>
                                        <ul className="categories_mas_infos">
                                            <li><Link to="/parquet/massif/les-bruts/stock-et-commande">
                                                Les Bruts</Link></li>
                                            <li><Link to="/parquet/massif/les-huilés/stock-et-commande">
                                                Les Huilés</Link></li>
                                            <li><Link to="/parquet/massif/les-vernis/stock-et-commande">
                                                Les Vernis</Link></li>
                                            <li><Link to="/parquet/massif/les-géométriques/stock-et-commande">
                                                Les Géométriques</Link></li>
                                        </ul>
                                    </div>
                                    <div className="categories_con">
                                        <Link to="/parquet/contrecollé/pas-de-sous-categorie/stock-et-commande" className="categories_con_titre">
                                            <span className="categories_con_titre_logo"><img alt="Catégorie sélectionnée" src={LogoPetit}></img></span>
                                            <h3>Contrecollé</h3>
                                        </Link>
                                        <ul className="categories_con_infos">
                                            <li><Link to="/parquet/contrecollé/les-huilés/stock-et-commande">
                                                Les Huilés</Link></li>
                                            <li><Link to="/parquet/contrecollé/les-vernis/stock-et-commande">
                                                Les Vernis</Link></li>
                                            <li><Link to="/parquet/contrecollé/point-de-hongrie/stock-et-commande">
                                                Point de Hongrie</Link></li>
                                            <li><Link to="/parquet/contrecollé/dalle-versailles/stock-et-commande">
                                                Dalle Versailles</Link></li>
                                            <li><Link to="/parquet/contrecollé/bâton-rompu/stock-et-commande">
                                                Bâton Rompu</Link></li>
                                        </ul>
                                    </div>
                                    <div className="categories_lmp">
                                        <Link to="/parquet/lame-murale-polystyrène/pas-de-sous-categorie/stock-et-commande" className="categories_lmp_titre">
                                            <span className="categories_lmp_titre_logo"><img alt="Catégorie sélectionnée" src={LogoPetit}></img></span>
                                            <h3>Lame Murale Polystyrène</h3>
                                        </Link>
                                        <ul className="categories_lmp_infos">
                                            <li><Link to="/parquet/lame-murale-polystyrène/effet-bois/stock-et-commande">
                                                Effet Bois</Link></li>
                                            <li><Link to="/parquet/lame-murale-polystyrène/effet-tissu/stock-et-commande">
                                                Effet Tissu</Link></li>
                                            <li><Link to="/parquet/lame-murale-polystyrène/effet-métal/stock-et-commande">
                                                Effet Métal</Link></li>
                                        </ul>
                                    </div>
                                    <div className="categories_str">
                                        <Link to="/parquet/stratifié-&-pvc/pas-de-sous-categorie/stock-et-commande" className="categories_str_titre">
                                            <span className="categories_str_titre_logo"><img alt="Catégorie sélectionnée" src={LogoPetit}></img></span>
                                            <h3>Stratifié & PVC</h3>
                                        </Link>
                                        <ul className="categories_str_infos">
                                            <li><Link to="/parquet/stratifié-&-pvc/les-stratifiés/stock-et-commande">
                                                Les Stratifiés</Link></li>
                                            <li><Link to="/parquet/stratifié-&-pvc/les-pvc/stock-et-commande">
                                                Les PVC</Link></li>
                                        </ul>
                                    </div>
                                    <div className="categories_accPar">
                                        <Link to="/parquet/accessoires-parquet/pas-de-sous-categorie/stock-et-commande" className="categories_accPar_titre">
                                            <span className="categories_accPar_titre_logo"><img alt="Catégorie sélectionnée" src={LogoPetit}></img></span>
                                            <h3>Accessoires Parquet</h3>
                                        </Link>
                                        <ul className="categories_accPar_infos">
                                            <li><Link to="/parquet/accessoires-parquet/plinthes/stock-et-commande">
                                                Plinthes</Link></li>
                                            <li><Link to="/parquet/accessoires-parquet/colles/stock-et-commande">
                                                Colles</Link></li>
                                            <li><Link to="/parquet/accessoires-parquet/ragréages-et-primaires/stock-et-commande">
                                                Ragréages et primaires</Link></li>
                                            <li><Link to="/parquet/accessoires-parquet/sous-couche-et-isolation/stock-et-commande">
                                                Sous-couche et isolation</Link></li>
                                            <li><Link to="/parquet/accessoires-parquet/produits-d'entretien/stock-et-commande">
                                                Produits d'entretien</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>    
                        <div className="header_gauche_megamenu_sanitaire">
                            <Link to="/promo/carrelage-et-parquet" className="bouton_sanitaire" aria-label="Promo">
                                <h2>Promo</h2>
                                <div className="header_gauche_megamenu_sanitaire_remise">-40%</div>
                            </Link>
                        </div>
                    </div>
                    <div className={`header_gauche_megamenu ${showLinks ? "show" : "hidden"}`}>
                        <a href="https://maisondurevetement.odoo.com/contact" className="bouton_contacts" aria-label="Footer">
                            <h2>Contact</h2>
                        </a>
                        <Link to="/livraison-et-retour" className="bouton_livraison" aria-label="Onglet Livraison et Retour">
                            <h2>Livraison</h2>
                        </Link>
                        <Link to="/en-stock/carrelage-et-parquet" className="bouton_destockage" aria-label="Onglet Déstockage">
                            <h2>Déstockage</h2>
                        </Link>
                    </div>
                    <div className={`header_gauche_megamenu header_gauche_icones ${showLinks ? "show" : "hidden"}`}>
                        <span onClick={productsService.getOrderId} title="Accéder à votre panier"><i className="cart fa-solid fa-cart-shopping"></i></span>
                        <Link to="/recherche/valeur=" aria-label="Page Recherche" title="Consulter uniquement les produits correspondants à votre recherche !"><i className="fa-solid fa-magnifying-glass magnifying-glass"></i></Link>
                        <Link to="/espace-client" aria-label="Mon Compte personnel" title="Accéder ou créer votre compte personnel !"><i className="fa-solid fa-user"></i></Link>
                    </div>
                </div>
                <div className="header_logo logo_blanc">
                    <Link to="/">
                        <img src={LogoBlanc} alt="logo en blanc de Maison du Revêtement"/>
                    </Link>
                </div>
                <div className="header_logo logo_noir">
                    <Link to="/">
                        <img src={LogoNoir} alt="logo en noir de Maison du Revêtement"/>
                    </Link>
                </div>
                <div className="header_droite">
                    <div className="header_droite_icones">
                        <div className="header_droite_icones_recherche">
                            <i className="iconeRecherche fa-solid fa-magnifying-glass" id="glass" onClick={handleClickGlass}></i>
                        </div>
                        <div className="header_gauche_icones_telephone">
                        <a href="https://maisondurevetement.odoo.com/contact"><i className="fa-solid fa-phone"></i></a>
                        </div>
                        <div className="header_droite_icones_panier">
                            <span onClick={productsService.getOrderId} title="Accéder à votre panier"><i className="fa-solid fa-cart-shopping cartAnimation"></i></span>
                        </div>
                    </div>
                    <BarreDeRecherche />
                </div>
            </nav>
        </>
    )
}