/* Modules */
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'

/* Dépendences */
import { productsService } from "../../_services/products.service";
// import { PanierContext } from "../../store/panier-context";

/* Composants */
import Header from "../../composants/Header"
import TitreGammes from "../../composants/TitreGammes"
import Aide from "../../composants/Aide"
import NavigationArticle from "../../composants/NavigationArticle"
import BoutonScrollToTop from "../../composants/BoutonScrollToTop"
import Footer from '../../composants/Footer'
import Erreur from "../autre/Erreur";

/* Images */
import PetitLogo from "../../assets/mdr_logonoir.png";

/* Code */
export default function Article() {
    /* Récupération des données de l'URL */
    let UrlParams  = useParams();
    let article = UrlParams.article;
    let artId = article.split("=").pop();

    /* Initialisation des autres variables nécessaires */
    let [oneCard, setOneCard] = useState("");
    let [cartId, setCartId] = useState(null);
    let [fullCat, setFullCat] = useState(null);
    let [attributes, setAttributes] = useState(null);
    let [imagePrincipale, setImagePrincipale] = useState(null);
    let [imageAutre, setImageAutre] = useState([]);
    let [compteurM, setCompteurM] = useState(null);
    let [compteurB, setCompteurB] = useState(null);
    let [isLoading, setIsLoading] = useState(true);
    let [artExists, setArtExists] = useState(true);

    /* Définition des catégories de l'article */
    let separation="";
    let gamme = "gamme";
    let produit = "produit";
    let sousCategorie = "pas-de-sous-categorie";

    if (fullCat !== null) {
        fullCat.split("/") !== "" ? separation = fullCat.split("/") : separation = null;
        separation[0] !== undefined ? gamme = separation[0].trim().replaceAll(' ', '-').toLowerCase() : gamme = null;
        separation[1] !== undefined ? produit = separation[1].trim().replaceAll(' ', '-').toLowerCase() : produit = null;
        separation[2] !== undefined ? sousCategorie = separation[2].trim().replaceAll(' ', '-').toLowerCase() : sousCategorie = null;
    }

    /* Récupération des infos de l'article sélectiionné */
    useEffect(() => {
        productsService.getOneCard(artId)
        .then((res) => {
            setOneCard(res.data);
            setCartId(res.data.product_variant_id);
            setImagePrincipale((res.data.img_url).replace('image_512', 'image_1024'));
            setImageAutre((res.data.additional_photos));
            setAttributes(res.data.attributes);
            setCompteurM(res.data.packaging.qty)
            setCompteurB(res.data.category.display_name.includes("Lame") ? 10 : 1)
            setFullCat((res.data.category.display_name));
            setIsLoading(false);
        })
        .catch((err) => {
            console.log(err.data);
            setArtExists(false);
        });
    }, [artId]);

    /* Fonctions relatives au bouton en surface en m² */
    let ajoutCompteurM = () => {
        if(compteurM <= (999 - oneCard.packaging.qty)) {
            let resM = (parseFloat(compteurM) + oneCard.packaging.qty).toFixed(2);
            setCompteurM(resM);
            let resB = (parseFloat(resM) / oneCard.packaging.qty).toFixed(0);
            setCompteurB(resB);
        }
    };

    let retraitCompteurM = () => {
        if(compteurM > oneCard.packaging.qty) {
            let resM = (parseFloat(compteurM) - oneCard.packaging.qty).toFixed(2);
            setCompteurM(resM);
            let resB = (parseFloat(resM) / oneCard.packaging.qty).toFixed(0);
            setCompteurB(resB);
        }
    };

    let nouvelleValeurM = (event) => {
        let valueM = (event.target.value).replace("," , ".").replace(";" , ".");
        setCompteurM(valueM);
    };

    /* Fonctions relatives au bouton en nombre de boîtes */
    let ajoutCompteurB = () => {
        if(produit === "lame-murale-polystyrène") {
            if(compteurB >= 10 && compteurB < (999 / oneCard.packaging.qty)) {
                let resB = (parseInt(compteurB) + 1).toFixed(0);
                setCompteurB(resB);
                let resM = (parseFloat(resB) * oneCard.packaging.qty).toFixed(2);
                setCompteurM(resM);
            }
        } else {
            if(compteurB >= 1 && compteurB < (999 / oneCard.packaging.qty)) {
                let resB = (parseInt(compteurB) + 1).toFixed(0);
                setCompteurB(resB);
                let resM = (parseFloat(resB) * oneCard.packaging.qty).toFixed(2);
                setCompteurM(resM);
            }
        }
    };

    let retraitCompteurB = () => {
        if(produit === "lame-murale-polystyrène") {
            if(compteurB > 10 && compteurB <= (999 / oneCard.packaging.qty)) {
                let resB = (parseInt(compteurB) - 1).toFixed(0);
                setCompteurB(resB);
                let resM = (parseFloat(resB) * oneCard.packaging.qty).toFixed(2);
                setCompteurM(resM);
            }
        } else {
            if(compteurB > 1 && compteurB <= (999 / oneCard.packaging.qty)) {
                let resB = (parseInt(compteurB) - 1).toFixed(0);
                setCompteurB(resB);
                let resM = (parseFloat(resB) * oneCard.packaging.qty).toFixed(2);
                setCompteurM(resM);
            }
        }
    };

    let nouvelleValeurB = (event) => {
        let valueB = (event.target.value).replace("," , ".").replace(";" , ".");
        setCompteurB(valueB);
    };

    /* Focus Out */
    let checkValeurM = () => {
        if(compteurM >= oneCard.packaging.qty && compteurM <= 999) {
            let res = compteurM / oneCard.packaging.qty;
            if(res % 1 !== 0) {
                setCompteurB(parseInt(res) + 1);
                setCompteurM(((parseInt(res) + 1) * oneCard.packaging.qty).toFixed(2));
            } else {
                setCompteurB(parseInt(res));
                setCompteurM(((parseInt(res)) * oneCard.packaging.qty).toFixed(2));
            }
        } else {
            setCompteurM(oneCard.packaging.qty);
            setCompteurB(1);
        }
    };

    let checkValeurB = () => {
        if(produit === "lame-murale-polystyrène") {
            if(compteurB >= 10 && compteurB <= (999 / oneCard.packaging.qty)) {
                let resB = parseInt(compteurB);
                setCompteurB(resB);
                let resM = (resB * oneCard.packaging.qty).toFixed(2)
                setCompteurM(resM);   
            } else {
                setCompteurB(10);
                setCompteurM(oneCard.packaging.qty);
            }

        } else {
            if(compteurB >= 1 && compteurB <= (999 / oneCard.packaging.qty)) {
                let resB = parseInt(compteurB);
                setCompteurB(resB);
                let resM = (resB * oneCard.packaging.qty).toFixed(2)
                setCompteurM(resM);   
            } else {
                setCompteurB(1);
                setCompteurM(oneCard.packaging.qty);
            }
        }

    }

    /* On regarde si le produit est en stock */
    let qtyAvailable = false;
    if (oneCard !== null) {
        if(oneCard.stock === true) {
            qtyAvailable = true;
        }
    }

    /* Ajout d'un article au panier */
    // const { addItemToCard } = useContext(PanierContext);

    function addToCart() {
        productsService.addToCart({
            product_variant_id : cartId,
            quantity : parseFloat(compteurM),
            variant_values : [
              0
            ]
        })
        .then((res) => {
            if(fullCat.includes("Formats XXL")){
                productsService.addToCart({
                    product_variant_id : 58991,
                    quantity : parseInt(compteurM/60.47) + 1,
                    variant_values : [
                      0
                    ]
                })
            }
            console.log("OK, " + res);
        })
        .catch((err) => {
            console.log("KO, " + err);
        })
        cartClick();
    }

    /* Animations lors de l'ajout d'un article au panier */
    function cartClick(){
        document.querySelector(".article_droite_achat_boutonAchat").classList.add('clicked');
        document.querySelector(".article_droite_achat_boutonPanier").classList.add('clicked');
        document.querySelector(".cartAnimation").classList.add('clicked');
        setTimeout(() => {
            document.querySelector(".article_droite_achat_boutonAchat").classList.remove('clicked');
        }, 3000);
        setTimeout(() => {
            document.querySelector(".article_droite_achat_boutonPanier").classList.remove('clicked');
        }, 8000);
        setTimeout(() => {
            document.querySelector(".cartAnimation").classList.remove('clicked');
        }, 15000);
    }

    /* Fonctions au click pour changer l'image principale */
    let [index, setIndex] = useState(0);

    const handlePrimaryPicture = (i) => {
        setIndex(i);
    }

    const photoSuivante = () => {
        if (index < imageAutre.length - 1) {
            setIndex(index + 1);
        }
        if (index === imageAutre.length - 1) {
            setIndex(0);
        }
    }

    const photoPrécédente = () => {
        if (index > 0) {
            setIndex(index - 1);
        }

        if (index === 0) {
            setIndex(imageAutre.length - 1);
        }
    }

    useEffect(() => {
        if((imageAutre[index] !== undefined)) {
            setImagePrincipale((imageAutre[index]).replace('image_512', 'image_1024'));
        }
    }, [index]);
    
    /* Fonctions au click pour afficher l'image principale en grand */
    const [showFullScreen, setShowFullScreen] = useState(false);
    const handleImageClick = () => {
        setShowFullScreen(true);
    };
    const handleCloseFullScreen = () => {
        setShowFullScreen(false);
    };

    /* Modification du titre et de la description de la page */
    useEffect(() => {
        document.title = `${oneCard.barcode} | Maison du Revêtement`;
        document.getElementsByTagName("meta")[2].content= `Consulter les informations relatives à l'article ${oneCard.barcode} telles que : son prix, sa disponibilité en stock et ses caractéristiques. Ajouter la quantité en m² ou en nombre de boîtes que vous souhaitez ou demander également un échantillon si vous préférez.`;
    }, [oneCard]);

    return (
        <>
            { artExists ?
                <>
                    { isLoading ?
                        <div className="page-spinner">
                            <div className="page-spinner_interieur">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    :
                        <>
                            <header className={`header header_article_${gamme}`}>
                                <Header />
                                <TitreGammes titre={fullCat.split("/").join("")} />
                            </header>
                            <main className="articleMain">
                                <NavigationArticle gamme={gamme} produit={produit} sousCategorie={sousCategorie} oneCard={oneCard} cat={fullCat.split("/")}/>
                                <section className="article">
                                    <div className="article_gauche">
                                        <div className="article_gauche_blocPhoto">
                                            { showFullScreen ?
                                                <>
                                                    <div className="article_gauche_blocPhoto_photoPrincipale_fullscreen">
                                                        <img src={imagePrincipale} onClick={handleCloseFullScreen} alt="Visualisation principale du produit en plein écran"/>
                                                        { imageAutre.length >= 2 && imageAutre.length <= 8 ?
                                                            <>
                                                                <div onClick={photoPrécédente} className="article_gauche_blocPhoto_chevronGaucheFullscreen"><i className="fa-solid fa-chevron-left fa-2xl"></i></div>
                                                                <div onClick={photoSuivante} className="article_gauche_blocPhoto_chevronDroiteFullscreen"><i className="fa-solid fa-chevron-right fa-2xl"></i></div>
                                                            </>
                                                        : 
                                                            null
                                                        }
                                                        <div onClick={handleCloseFullScreen} className="article_gauche_blocPhoto_croixFullscreen"><i className="fa-solid fa-xmark fa-2xl"></i></div>
                                                    </div>
                                                    <div className="article_gauche_blocPhoto_photoPrincipale">
                                                        <img src={imagePrincipale} alt="Visualisation principale du produit"/>
                                                    </div>
                                                </>
                                            :
                                                <>
                                                    <div className="article_gauche_blocPhoto_photoPrincipale" onClick={handleImageClick}>
                                                        <img src={imagePrincipale} alt="Visualisation principale du produit"/>
                                                        { artId === "21466" || artId === "21467" || artId === "21468" || artId === "21469" || artId === "21694" || artId === "28158" || artId === "28166" || artId === "28168" || artId === "28169" || artId === "28170" ?
                                                            <div className="bullePromo">PRIX USINE</div>
                                                        :
                                                            <>
                                                                { oneCard.discount !== 0 ?
                                                                    <div className="bullePromo">- {oneCard.discount} %</div>     
                                                                :
                                                                    null
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                    { imageAutre.length >= 2 && imageAutre.length <= 8 ?
                                                        <>
                                                            <div onClick={photoPrécédente} className="article_gauche_blocPhoto_chevronGauche"><i className="fa-solid fa-chevron-left fa-xl"></i></div>
                                                            <div onClick={photoSuivante} className="article_gauche_blocPhoto_chevronDroite"><i className="fa-solid fa-chevron-right fa-xl"></i></div>
                                                        </>
                                                    : 
                                                        null 
                                                    }
                                                </>
                                            }
                                        </div>  
                                        { imageAutre.length >= 2 && imageAutre.length <= 8 ?
                                            <div className="article_gauche_photoAutre">
                                                { imageAutre.map((img, i) => { return(
                                                    <div key={img} className="article_gauche_photoAutre_unePhoto">
                                                        <img src={img} onClick={() => {handlePrimaryPicture(i)}} alt="Autres visualisations du produit"></img>
                                                    </div>
                                                )})}
                                            </div>
                                        : 
                                            null
                                        }
                                        { attributes && attributes.length > 0 ?
                                            <div className="article_gauche_caracteristique">
                                                <h2 className="article_gauche_caracteristique_titre">
                                                    Caractéristiques du Produit :
                                                </h2>
                                                <div className="article_gauche_caracteristique_bloc">
                                                    { attributes.map((att) => {
                                                        return (
                                                            <div className="article_gauche_caracteristique_bloc_ligne" key={att.type}>
                                                                <p>{att.type}</p>
                                                                <p>{att.value}</p>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        :
                                            null
                                        }
                                    </div>
                                    <div className="article_droite fixed">
                                        <div className="article_droite_infos">
                                            <div className="article_droite_infos_blocUn">
                                                <h1 className="article_droite_infos_blocUn_nom">{oneCard.barcode}</h1>
                                                <span className="article_droite_infos_blocUn_description">{oneCard.description_sale ? oneCard.description_sale.toUpperCase() : null}</span>
                                            </div>
                                            <div className="article_droite_infos_blocDeux">
                                                <div className="article_droite_infos_blocDeux_prix">
                                                    <div className="article_droite_infos_blocDeux_prix_haut">
                                                        <p className="article_droite_infos_blocDeux_prix_haut_montant">{(oneCard.price_without_taxes * (1 - (oneCard.discount / 100))).toFixed(2)} €</p>
                                                        <p className="article_droite_infos_blocDeux_prix_haut_devise">HT{ produit === "lame-murale-polystyrène" || produit === "accessoires-carrelage" || produit === "accessoires-parquet" ?  ` / ${oneCard.packaging.name}` : "/m²" }</p>
                                                    </div>
                                                </div>
                                                <div className="article_droite_infos_blocDeux_stock">
                                                    <div className="article_droite_infos_blocDeux_stock_haut">
                                                        { qtyAvailable ?
                                                            <p>EN STOCK</p>
                                                        : 
                                                            <p>SUR COMMANDE</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>                                            
                                            { fullCat.includes("Formats XXL") ?
                                                <p className="article_droite_infos_xxl">Attention : Ce produit fragile et volumineux nécéssite une caisse de transport adaptée. Une caisse permet le transport de 60m² de plaques et est facturée 180 €HT. Le nombre de caisses nécessaire sera ajouté automatiquement à votre panier lors de l'ajout de cet article.</p>
                                            :
                                                null
                                            }
                                        </div>
                                        <div className="article_droite_achat">
                                            <div className="article_droite_achat_calcul">
                                                { produit === "lame-murale-polystyrène" || produit === "accessoires-carrelage" || produit === "accessoires-parquet" ?
                                                    null
                                                :
                                                    <div className="article_droite_achat_calcul_container">
                                                        <label htmlFor="boutonMetrage" className="article_droite_achat_calcul_container_texteCalcul">Surface en m²</label>
                                                        <div className="article_droite_achat_calcul_container_boutonCalcul">
                                                            <button className="article_droite_achat_calcul_container_boutonCalcul_boutonMoins" onClick={retraitCompteurM} aria-label="enlever un au compteur">
                                                                <FontAwesomeIcon icon={faMinus} size="xl" />
                                                            </button>
                                                            <button aria-label="nombre de mètres-carré">
                                                                <input name="boutonMetrage" type="text" min="1" max="999" step="0.01" value={compteurM} onChange={nouvelleValeurM} onBlur={checkValeurM} aria-label="nombre de mètres-carré"></input>
                                                            </button>
                                                            <button className="article_droite_achat_calcul_container_boutonCalcul_boutonPlus" onClick={ajoutCompteurM} aria-label="ajouter un au compteur">
                                                                <FontAwesomeIcon icon={faPlus} size="xl" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="article_droite_achat_calcul_container">
                                                    <label htmlFor="boutonBoite" className="article_droite_achat_calcul_container_texteCalcul">
                                                        { artId === "46528" ?
                                                            <p>Rouleaux de <strong>{oneCard.packaging.qty}</strong> m²</p>
                                                        :
                                                            <>
                                                                { oneCard.packaging.product_uom_id[1] === "m²" ? 
                                                                    <p>Boîtes de <strong>{oneCard.packaging.qty}</strong> m²</p>
                                                                : 
                                                                    <p>Nombre de {oneCard.packaging.name}</p>    
                                                                }
                                                            </>
                                                        }
                                                    </label>
                                                    <div className="article_droite_achat_calcul_container_boutonCalcul">
                                                        <button className="article_droite_achat_calcul_container_boutonCalcul_boutonMoins" onClick={retraitCompteurB} aria-label="enlever un au compteur">
                                                            <FontAwesomeIcon icon={faMinus} size="xl" />
                                                        </button>
                                                        <button aria-label="nombre de boites">
                                                            { produit === "lame-murale-polystyrène" ?
                                                                <input name="article_droite_achat_calcul_container_boutonBoite" type="text" min="10" max="999" step="1" value={compteurB} onChange={nouvelleValeurB} onBlur={checkValeurB} aria-label="nombre de boites"></input>
                                                            :
                                                                <input name="article_droite_achat_calcul_container_boutonBoite" type="text" min="1" max="999" step="1" value={compteurB} onChange={nouvelleValeurB} onBlur={checkValeurB} aria-label="nombre de boites"></input>
                                                            }
                                                        </button>
                                                        <button className="article_droite_achat_calcul_container_boutonCalcul_boutonPlus" onClick={ajoutCompteurB} aria-label="ajouter un au compteur">
                                                            <FontAwesomeIcon icon={faPlus} size="xl" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="article_droite_achat_prixCalcul">
                                                <p>Total HT : {((oneCard.price_without_taxes * (1 - (oneCard.discount / 100))) * compteurM).toFixed(2)} €</p>
                                                <p>Total TTC : {(((oneCard.price_without_taxes * (1 - (oneCard.discount / 100))) * compteurM) * 1.2).toFixed(2)} €</p>
                                            </span>
                                            <div className="article_droite_achat_boutonAchat" onClick={addToCart}> 
                                                <button className="cart-button">
                                                    <div className="slide"></div>
                                                    <span className="add-to-cart">AJOUTER AU PANIER</span> 
                                                    <span className="added">PANIER MIS A JOUR</span> 
                                                    <i className="fa fa-shopping-cart"></i> 
                                                    <i className="fa fa-square"></i> 
                                                </button> 
                                            </div>
                                            <button className="article_droite_achat_boutonPanier" onClick={productsService.getOrderId}>
                                                <span title="Accéder à votre panier">ACCÉDER A MON PANIER</span>
                                            </button>
                                            <div className="article_droite_achat_conseils">
                                                <div className="article_droite_achat_conseils_gauche">
                                                    <img src={PetitLogo} alt="Petit Logo Maison du Revêtement"/>
                                                </div>
                                                <div className="article_droite_achat_conseils_droite">
                                                    <span>Une question ou besoin de conseils ?</span>
                                                    <span>Appelez-nous au <a href="tel:+33182830071">01.82.83.00.71</a></span>
                                                </div>
                                            </div>
                                            <div className="article_droite_achat_echantillon">
                                                <h2 className="article_droite_achat_echantillon_titre">
                                                    Besoin d'un Échantillon de Carrelage ?
                                                </h2>
                                                <span className="article_droite_achat_echantillon_texte">
                                                    Afin de déterminer au mieux si ce produit correspond à vos attentes,<br />
                                                    Maison du Revêtement propose l'envoi d'échantillons de petite taille.<br />
                                                    Ces échantillons ne sont pas payants, seul leur envoi l'est et vous sera remboursé en totalité si vous confirmez plus tard une commande.<br />
                                                    L'envoi est facturé à hauteur de 30 €HT (36 €TTC) pour le premier échantillon, plus 15€HT (18€TTC) par référence supplémentaire.<br />
                                                    Ce service est limité à 3 échantillons par foyer et ne s'applique pas à tous les articles du site internet.
                                                </span>
                                                <button className="article_droite_achat_boutonEchantillon"><a href="https://maisondurevetement.odoo.com/contact">COMMANDER UN ÉCHANTILLON</a></button>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <Aide catName={fullCat.split(" /", 1).join()} />
                            </main>
                            <BoutonScrollToTop />
                            <Footer />
                        </>
                    }
                </>
            :
                <Erreur texte={"Cet article ne semble pas exister !"}/>
            } 
        </>
    )
}